import React from "react"
import styled from "styled-components"
import { PageRendererProps, navigate } from "gatsby"
import FadeIn from "@/components/FadeIn"
import SEO from "@/components/SEO"
import headerBgImage from "@/images/devops-header-bg.jpg"
import aboutBgImage from "@/images/devops-reason-bg.jpg"
import pointsBgImage from "@/images/zen-mark-bg.svg"
import githubMarkImage from "@/images/github-mark.svg"
import advisoryImage from "@/images/devops-advisory.png"
import trainingImage from "@/images/devops-training.jpg"
import partnerImage from "@/images/devops-partner.jpg"
import githubPartnerLogoImage from "@/images/gh-partner-logo.png"
import githubSecurityPartnerLogoImage from "@/images/gh-security-partner-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBook,
  faChartLine,
  faCheckSquare,
  faClipboardList,
  faCommentDots,
  faLightbulb,
  faYenSign,
} from "@fortawesome/free-solid-svg-icons"
import octocatImage from "@/images/octocat.png"

type DevopsGithubPageProps = PageRendererProps

const title = "DevOps with GitHub"
const description =
  "GitHub Enterprise のライセンス販売から GitHub のトレーニング・コンサルティングまで。15年以上にわたる DevOps 関連サービスを行なってきたゼンアーキテクツだからこそできる最適な解決策を提供します。"

const ContactButton: React.FC = () => (
  <Contact onClick={() => navigate("/contacts?devops=true")}>
    お問い合わせはこちら
  </Contact>
)

const DevopsGithub: React.FC<DevopsGithubPageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <Header>
        <Background></Background>
        <Content>
          <div className="text">
            <FadeIn offset={0} delay={200}>
              <h1 className="title">
                DevOps with GitHub
                <img src={githubMarkImage} className="mark" />
              </h1>
              <p className="description">
                GitHub Enterprise のライセンス販売から GitHub
                のトレーニング・コンサルティングまで。15年以上にわたる DevOps
                関連サービスを行なってきたゼンアーキテクツだからこそできる最適な解決策を提供します。
              </p>
            </FadeIn>
            <FadeIn offset={0} delay={400}>
              <ContactButton />
            </FadeIn>
          </div>
        </Content>
      </Header>
      <AboutSection>
        <div className="content">
          <div className="text">
            <div className="info">
              <div className="title">GitHub Enterprise とは？</div>
              <div className="description">
                世界でもっとも使われている「GitHub」を、大規模なビジネスにも。セキュアでスピード感のある開発体験を実現します。中でも「GitHub
                Enterprise Cloud」は SaaS
                型のサービスであり、お客様側でインフラの管理は全く必要ありません。
              </div>
            </div>
            <img src={githubPartnerLogoImage} className="logo" />
            ゼンアーキテクツは GitHub 公式パートナーに認定されており、 GitHub
            社との強固なコラボレーションを活かしたソリューションを提供しています。
          </div>
        </div>
      </AboutSection>
      <StrengthSection>
        <h2 className="title">Our Strengths</h2>
        <div className="blocks">
          <div className="block">
            <div className="text">
              <h2 className="title">安心のアドバイザリーサポート</h2>
              <div className="description">
                ライセンスをご購入のお客様には、DevOpsのエキスパートによる安心の
                QA サポートを提供しております。GitHub
                の使い方やベストプラクティス、最新情報などを日本語で共有いたします。
                専用のポータル ZEN Advisor を使って、オンラインフォーラム形式で
                GitHub や DevOps に関するお悩みの解決をサポートします。
                <div className="heading">
                  <span className="icon">
                    <FontAwesomeIcon icon={faCommentDots} />
                  </span>
                  回答事例
                </div>
                オンラインにてスペシャリストがお客様のお困りごとに都度対応します。
                <ul className="list">
                  <li className="item">GitHub Actions のサンプルコード</li>
                  <li className="item">Issue /Pull Request のストラテジー</li>
                  <li className="item">
                    Organization/User 管理ベストプラクティス
                  </li>
                </ul>
              </div>
            </div>
            <div className="image">
              <img src={advisoryImage} />
            </div>
          </div>
          <div className="block">
            <div className="text">
              <h2 className="title">GitHub 公式トレーニングも提供可能</h2>
              <div className="description">
                ご要望に応じて、日本語での GitHub
                公式トレーニングの実施も可能です。 GitHub
                認定済みのエンジニアが提供するハンズオン形式のトレーニングにより、実践的な学習が可能です。GitHub
                社がこれまで培ってきたトレーニングのノウハウと、ゼンアーキテクツの
                DevOps
                の知識を最大限に活用してお届けします。どのトレーニングが必要かなど、事前のご相談にも対応いたします。
                <div className="heading">
                  <span className="icon">
                    <FontAwesomeIcon icon={faClipboardList} />
                  </span>
                  トレーニングメニュー
                </div>
                <ul className="list">
                  <li className="item">
                    Administration Training: 管理者権限の扱い方や Organization
                    の設計の学習
                  </li>
                  <li className="item">
                    GitHub for Developers:
                    開発者向けのGitの概念やGitHubの基本的な使い方の学習
                  </li>
                  <li className="item">
                    GitHub Actions Training: CI/CDの重要性や使い方の学習、GitHub
                    Actionsの実践
                  </li>
                </ul>
              </div>
            </div>
            <div className="image">
              <img src={trainingImage} />
            </div>
          </div>
          <div className="block">
            <div className="text">
              <h2 className="title">日本初のAdvanced Security Partner</h2>
              <div className="description">
                <img src={githubSecurityPartnerLogoImage} className="logo" />
                ゼンアーキテクツは日本初の Advanced Security Partner
                として認定を受け、従来から推進してきたアジャイル開発を軸とした開発ワークフローの考え方にセキュリティの観点を加えた
                DevSecOps を推進しています。GitHub Advnaced Security
                を活用した、エンタープライズレベルのアプリケーションセキュリティ構築サポートにも対応しています。
                <div className="heading">
                  <span className="icon">
                    <FontAwesomeIcon icon={faLightbulb} />
                  </span>
                  GitHub Advanced Security とは？
                </div>
                通常のソースコード品質改善・維持の機能に加えて、Code scanning や
                Secret
                scanning、依存関係のレビューなどの機能を備えており、様々なセキュリティチェックが実現可能です。
                <ul className="list">
                  <li className="item">
                    潜在的なセキュリティの脆弱性とコーディングエラーを検索します
                  </li>
                  <li className="item">
                    ソースコード内のシークレット（キーやトークンなど）を即時に検出します
                  </li>
                  <li className="item">
                    ソースコード内の脆弱性を高精度で検知します
                  </li>
                  <li className="item">
                    利用している OSS ライブラリ等の依存関係を常に安全に保ちます
                  </li>
                </ul>
              </div>
            </div>
            <div className="image">
              <img src={partnerImage} />
            </div>
          </div>
        </div>
      </StrengthSection>
      <ReasonSection>
        <div className="content">
          <h2 className="title">
            DevOps with GitHub はこんなお客様に選ばれています
          </h2>
          <div className="reasons">
            <div className="reason">
              <div className="illust">
                <div className="icon">
                  <FontAwesomeIcon icon={faYenSign} />
                </div>
              </div>
              <div className="description">
                <span className="icon">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </span>
                GitHub Enterprise ライセンスを円で購入したい
              </div>
            </div>
            <div className="reason">
              <div className="illust">
                <div className="icon">
                  <FontAwesomeIcon icon={faChartLine} />
                </div>
              </div>
              <div className="description">
                <span className="icon">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </span>
                GitHub 導入中だが、さらに効果的に活用したい
              </div>
            </div>
            <div className="reason">
              <div className="illust">
                <div className="icon">
                  <FontAwesomeIcon icon={faBook} />
                </div>
              </div>
              <div className="description">
                <span className="icon">
                  <FontAwesomeIcon icon={faCheckSquare} />
                </span>
                開発規模に合わせた GitHub の運用方法が知りたい
              </div>
            </div>
          </div>
        </div>
      </ReasonSection>
      <ContactSection>
        <div className="content">
          <div className="description">
            お客様のニーズに合わせて最適なソリューションを提供します。
            <br />
            まずはフォームからお気軽にご相談ください。
          </div>
          <ContactButton />
        </div>
      </ContactSection>
    </Root>
  </>
)

const Root = styled.div`
  background: ${props => props.theme.secondaryLightColor};
  color: ${props => props.theme.textColor};
`

const Header = styled.div`
  position: relative;

  @media ${props => props.theme.minMdUp} {
    height: 700px;
  }

  @media ${props => props.theme.minLgUp} {
    height: 600px;
  }

  @media ${props => props.theme.maxSmDown} {
    height: 500px;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-image: url(${headerBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.7);

  @media ${props => props.theme.minMdUp} {
    background-position: top right;
  }

  @media ${props => props.theme.maxSmDown} {
    background-position: 70% center;
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 20%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 50%, transparent);
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;

  @media ${props => props.theme.minMdUp} {
    max-width: 1200px;
    left: 50%;
    transform: translateX(-50%);
  }

  .text {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${props => props.theme.minMdUp} {
      width: 70%;
      padding: 0 60px;
    }

    @media ${props => props.theme.minLgUp} {
      width: 60%;
    }

    @media ${props => props.theme.maxSmDown} {
      padding: 30px;
    }

    .title {
      font-family: "Teko", sans-serif;
      line-height: 0.8;
      font-weight: normal;
      max-width: 500px;

      @media ${props => props.theme.minMdUp} {
        font-size: 100px;
        margin-top: 50px;
      }

      @media ${props => props.theme.minLgUp} {
        font-size: 120px;
      }

      @media ${props => props.theme.maxSmDown} {
        font-size: 80px;
        margin-top: 50px;
      }
    }

    .mark {
      @media ${props => props.theme.minMdUp} {
        margin-left: 20px;
        height: 80px;
      }
      @media ${props => props.theme.maxSmDown} {
        margin-left: 10px;
        height: 60px;
      }
    }

    .description {
      line-height: 1.75;

      @media ${props => props.theme.minMdUp} {
        margin: 20px 0 40px;
      }

      @media ${props => props.theme.maxSmDown} {
        margin: 20px 0 30px;
        font-size: 14px;
      }
    }
  }
`

const Contact = styled.button`
  color: ${props => props.theme.primaryDarkColor};
  background-color: #fff;
  border-radius: 40px;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }

  @media ${props => props.theme.minMdUp} {
    padding: 20px 60px;
    font-size: 22px;
  }

  @media ${props => props.theme.minLgUp} {
    font-size: 24px;
  }

  @media ${props => props.theme.maxSmDown} {
    padding: 15px;
    width: 100%;
    font-size: 18px;
  }
`

const AboutSection = styled.section`
  background-color: #2c6c8b;

  @media ${props => props.theme.minMdUp} {
    padding: 60px 30px;
  }
  @media ${props => props.theme.maxSmDown} {
    padding: 50px 30px;
  }

  .content {
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-image: url(${octocatImage});
    background-repeat: no-repeat;

    @media ${props => props.theme.maxMdDown} {
      flex-direction: column-reverse;
      background-position: bottom;
      background-size: 70%;
      max-width: 500px;
    }
    @media ${props => props.theme.minLgUp} {
      max-width: 1200px;
      min-height: 400px;
      background-size: contain;
    }
    @media ${props => props.theme.maxSmDown} {
      background-size: contain;
    }
  }

  .text {
    color: #fff;
    margin: 0 auto;
    line-height: 1.75;
    text-align: justify;

    @media ${props => props.theme.minLgUp} {
      margin-left: 500px;
    }
    @media ${props => props.theme.maxMdDown} {
      margin-bottom: 320px;
    }

    .logo {
      display: block;
      height: 60px;

      @media ${props => props.theme.minLgUp} {
        margin-bottom: 20px;
      }
      @media ${props => props.theme.maxMdDown} {
        margin: 20px auto;
      }
    }
  }

  .info {
    border-radius: 4px;
    position: relative;

    @media ${props => props.theme.minMdUp} {
      margin: 20px auto 60px;
    }

    .title {
      border-radius: 4px;
      font-size: 22px;

      @media ${props => props.theme.maxMdDown} {
        text-align: center;
      }
    }

    .description {
      margin-top: 20px;
      line-height: 1.75;
      text-align: justify;
    }
  }
`

const StrengthSection = styled.section`
  background-color: #fff;

  @media ${props => props.theme.minMdUp} {
    padding: 50px 0 60px;
  }
  @media ${props => props.theme.maxSmDown} {
    padding: 40px 0 0;
  }

  > .title {
    text-align: center;
    font-family: "Teko", sans-serif;
    font-weight: 500;
    color: ${props => props.theme.primaryDarkColor};

    @media ${props => props.theme.minMdUp} {
      font-size: 80px;
    }
    @media ${props => props.theme.maxSmDown} {
      font-size: 60px;
    }
  }

  .blocks {
    margin: 0 auto;
    padding: 30px 0;
  }

  .block {
    display: flex;
    justify-content: flex-end;

    @media ${props => props.theme.maxSmDown} {
      flex-direction: column-reverse;
    }

    & + .block {
      @media ${props => props.theme.minMdUp} {
        margin-top: 40px;
      }
      @media ${props => props.theme.maxSmDown} {
        margin-top: 20px;
      }
    }

    &:nth-child(even) {
      @media ${props => props.theme.minMdUp} {
        flex-direction: row-reverse;
      }

      .image {
        img {
          object-position: right;
        }
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${props => props.theme.minMdUp} {
        padding: 30px 40px;
        width: 500px;
      }
      @media ${props => props.theme.minLgUp} {
        padding: 40px 60px;
        width: 800px;
      }
      @media ${props => props.theme.maxSmDown} {
        padding: 30px;
      }

      .title {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 400;
        letter-spacing: 2px;
      }

      .description {
        line-height: 1.75;
        text-align: justify;

        .heading {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-weight: 600;
          color: ${props => props.theme.primaryDarkColor};

          .icon {
            margin-right: 8px;
            font-size: 20px;
          }
        }

        .list {
          margin-top: 8px;
          padding-left: 20px;
          font-size: 14px;
        }

        .logo {
          display: block;
          height: 40px;
          margin-bottom: 20px;
        }
      }
    }

    .image {
      overflow: hidden;

      @media ${props => props.theme.minMdUp} {
        border-radius: 4px;
        width: calc(40% - 30px);
      }
      @media ${props => props.theme.maxSmDown} {
        height: 260px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }
`

const ReasonSection = styled.section`
  background-image: url(${aboutBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${props => props.theme.minMdUp} {
    padding: 80px 30px;
  }
  @media ${props => props.theme.maxSmDown} {
    padding: 60px 30px;
  }

  .content {
    margin: 0 auto;
    line-height: 1.75;
    color: #fff;

    @media ${props => props.theme.minLgUp} {
      width: 1000px;
    }

    .title {
      font-size: 22px;
      text-align: center;
      font-weight: 400;
    }

    .reasons {
      display: flex;

      @media ${props => props.theme.minMdUp} {
        margin-top: 40px;
      }
      @media ${props => props.theme.maxSmDown} {
        margin-top: 20px;
        flex-direction: column;
      }

      .reason {
        display: flex;
        align-items: center;

        @media ${props => props.theme.minMdUp} {
          padding: 0 30px;
          width: 33.3%;
          flex-direction: column;
        }
        @media ${props => props.theme.maxSmDown} {
          padding: 10px;
        }

        .illust {
          background-color: #fff;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media ${props => props.theme.minMdUp} {
            width: 160px;
            height: 160px;
          }
          @media ${props => props.theme.maxSmDown} {
            width: 60px;
            height: 60px;
          }

          .icon {
            line-height: 0;
            color: #2c6c8b;

            @media ${props => props.theme.minMdUp} {
              font-size: 80px;
            }
            @media ${props => props.theme.maxSmDown} {
              font-size: 30px;
            }
          }
        }

        .description {
          display: flex;

          @media ${props => props.theme.minMdUp} {
            margin-top: 30px;
          }
          @media ${props => props.theme.maxSmDown} {
            width: calc(100% - 60px);
            padding-left: 10px;
          }

          .icon {
            color: ${props => props.theme.primaryDarkColor};
            margin-right: 8px;

            @media ${props => props.theme.maxSmDown} {
              display: none;
            }
          }
        }
      }
    }
  }
`

const ContactSection = styled.section`
  padding: 100px 30px;
  background-color: #001c2f;
  background-image: url(${pointsBgImage});
  background-repeat: no-repeat;
  background-size: auto 110%;
  background-position: calc(50% - 200px) 50%;

  .content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media ${props => props.theme.minMdUp} {
      max-width: 800px;
    }
  }

  .description {
    color: #fff;
    font-size: 22px;
    line-height: 1.75;
    margin-bottom: 60px;

    @media ${props => props.theme.minMdUp} {
      text-align: right;
    }
    @media ${props => props.theme.maxSmDown} {
      text-align: justify;
    }
  }
`

export default DevopsGithub
